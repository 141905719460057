$(() => {
  $('.more-details, .show-less').click(function (e) {
    e.preventDefault();
    $(this).closest('.company-descriptions').toggleClass('show-more');
  });

  $('.faq').click(function (e) {
    e.preventDefault();
    $(this).toggleClass('show-faq-answer');
  });
});
